import React from 'react'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import Layout from '../components/layout' 
import ReactMarkdown from "react-markdown"
import {Carousel} from 'react-bootstrap'
import SEO from "../components/seo"
import right from "./../images/right.svg"
import left from "./../images/left.svg"

const TeamTemplate = ({ data, pageContext }) => (
  <Layout>
    <SEO title={data.strapiTeam.meta_titel} description={data.strapiTeam.meta_omschrijving} lang="nl" />
    <div className="container">
      <div className="row no-gutters cta-wiezijnwe">
        <div className="col-lg-6 d-flex flex-column justify-content-center bg-wervel">
          <div className="cta-wiezijnwe-tekst">
            <h1>{data.strapiTeam.naam}</h1>
            <ReactMarkdown source={data.strapiTeam.tekst} />
          </div>
        </div>
        <div className="col-lg-6 cta-wiezijnwe-afb">
          <Carousel>
            {data.strapiTeam.afbeeldingen.sort((a, b) => (a.name > b.name) ? 1 : -1).map(document =>(
              <Carousel.Item key={document.id}>
                {document.width > document.height &&
                  <img
                    className="d-block w-100"
                    src={document.url}
                    alt={document.alternativeText}
                  />
                }
                {document.width < document.height &&
                  <img
                    className="d-block carouselItemPortrait"
                    src={document.url}
                    alt={document.alternativeText}
                  />
                }
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-9 mt-5 mb-5 team-opleidingen">
          <ReactMarkdown source={data.strapiTeam.opleidingen} />
        </div>
      </div>
      <div className="row mb-5">
        <div className="col-md-6 mb-2 mb-md-0">
          {pageContext.prev && pageContext.prev.naam && pageContext.prev.naam !== "" &&
            <Link to={`/wiezijnwe/${pageContext.prev.naam.replace(/\s/g, "").toLowerCase()}`}>
              <span className="btn btn-primary"><img src={left} height="24" alt="vorige teamlid" /> {pageContext.prev.naam}</span>
            </Link>
          }
        </div>
        <div className="col-md-6 text-right">
          {pageContext.next && pageContext.next.naam && pageContext.next.naam !== "" &&
            <Link to={`/wiezijnwe/${pageContext.next.naam.replace(/\s/g, "").toLowerCase()}`}>
              <span className="btn btn-primary">{pageContext.next.naam} <img src={right} height="24" alt="volgende teamlid" /></span>
            </Link>
          }
        </div>
      </div>
    </div>
  </Layout>
)

export default TeamTemplate

export const query = graphql`
  query TeamTemplate($id: String!) {
    strapiTeam(id: {eq: $id}) {
      id
      naam
      tekst
      opleidingen
      meta_titel
      meta_omschrijving
      afbeeldingen {
        id
        alternativeText
        width
        height
        name
        url
      }
    }
  }
`